<template>
  <section
    class="callout-section page-section"
    :class="content.classes"
  >
    <div class="container">
      <MediaBlock :content="content" />
      <div class="callout-content">
        <TitleBlock :content="content" />
        <ListBlock :content="content" />
        <SMSForm v-if="sms" />
        <SignUpForm v-else />
        <CTABlock :content="content" />
      </div>
    </div>
  </section>
</template>

<script>
import MediaBlock from './shared/MediaBlock.vue'
import TitleBlock from './shared/TitleBlock.vue'
import ListBlock from './shared/ListBlock.vue'
import CTABlock from './shared/CTABlock.vue'

const SignUpForm = () => import('./SignUpForm.vue')
const SMSForm = () => import('./SMSForm.vue')

export default {
  components: {
    MediaBlock,
    TitleBlock,
    ListBlock,
    CTABlock,
    SignUpForm,
    SMSForm,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    sms() {
      return window.location.pathname.includes('sms')
    },
  },
}
</script>
