<template>
  <section class="callout-section page-section" :class="content.classes">
    <div class="container">
      <MediaBlock :content="content" />
      <div class="callout-content">
        <TitleBlock :content="content" />
        <ListBlock :content="content" />
        <CTABlock :content="content" />
      </div>
    </div>
  </section>
</template>

<script>
import MediaBlock from "./shared/MediaBlock.vue";
import TitleBlock from "./shared/TitleBlock.vue";
import ListBlock from "./shared/ListBlock.vue";
import CTABlock from "./shared/CTABlock.vue";


export default {
  components: {
    MediaBlock,
    TitleBlock,
    ListBlock,
    CTABlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
