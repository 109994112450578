<template>
  <div class="results-container">
    <ResultsCallout :content="calloutContent" />
    <Reviews :content="quizData.reviews" />
    <PressCards :content="quizData.press_cards" />
    <Details :content="quizData.security_details" />
    <Callout :content="quizData.cta_callout" />
  </div>
</template>

<script>
import ResultsCallout from '../components/ResultsCallout.vue'
import Callout from '../components/Callout.vue'
import Reviews from '../components/Reviews.vue'
import PressCards from '../components/PressCards.vue'
import Details from '../components/Details.vue'
import { trackLoadEvent } from '../helpers/tracking'

export default {
  components: {
    ResultsCallout,
    Callout,
    Reviews,
    PressCards,
    Details,
  },
  data() {
    return {
      quizData: this.$quizData,
    }
  },
  computed: {
    calloutContent() {
      const baseContent = this.$quizData.results_callout
      const calculatedSavings = this.$store.getters.RESULTS.slice(1, 5).reduce(
        (acc, curr) => acc
            + Number(
              curr.content
                ? curr.content.split('-')[1].replace(/,/g, '').replace('+', '')
                : '0',
            ),
        0,
      )
        * 12
        * 0.05

      const savingsString = `${calculatedSavings}`
      const formatted = savingsString.length >= 4
        ? `$${savingsString.slice(0, 1)},${savingsString.slice(1)}`
        : `$${savingsString}`
      baseContent.subtitle = `up to <span class='savings'>${formatted}</span>`
      baseContent.text = `By using the Times Club app on your regular purchases, you can save an estimated ${formatted} every year. Just sign up below!`

      return baseContent
    },
  },
  mounted() {
    trackLoadEvent('quiz-ended')
  },
}
</script>
