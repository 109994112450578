<template>
  <div>
    <ProgressBar v-if="quizVersion === 'quiz-d' && !completed" />
    <ThesisForm v-if="quizVersion === 'quiz-d'" />
    <ThesisForm v-else>
      <template #introView>
        <Callout :content="quizData.callout_hero" />
      </template>
      <template #onePageIntro>
        <Callout :content="quizData.callout_hero" />
      </template>
      <template #loaderView>
        <CustomResults />
      </template>
      <template #footerSlot>
        <QuizFooter />
      </template>
    </ThesisForm>
  </div>
</template>

<script>
import Callout from './components/Callout.vue'
import CustomResults from './sections/CustomResults.vue'
import QuizFooter from './components/QuizFooter.vue'
import { trackLoadEvent } from './helpers/tracking'
import ProgressBar from './components/ProgressBar.vue'

export default {
  components: {
    Callout,
    CustomResults,
    QuizFooter,
    ProgressBar,
  },
  data() {
    return {
      quizData: this.$quizData,
      quizVersion: this.$quizData.quiz,
    }
  },
  computed: {
    completed() {
      return this.$store.getters.QUIZ_COMPLETED
    },
  },
  mounted() {
    this.addEventToCTA()
  },
  created() {
    this.$store.dispatch('INITIALIZE_STORE', this.$quizData)
    this.$store.dispatch(
      '/results/INITIALIZE_RESULTS',
      this.$quizData.sequences,
    )
  },
  methods: {
    addEventToCTA() {
      const startQuizCTA = document.querySelector('.quiz-callout-hero .btn')

      if (startQuizCTA) {
        startQuizCTA.addEventListener('click', e => {
          e.preventDefault()
          this.$store.dispatch('QUIZ_STARTED')
          trackLoadEvent('quiz-started')
        })
      }
    },
  },
}
</script>
