<template>
  <footer class="tf-footer">
    <div class="footer-meta">
      <p class="footer-copyright">
        <span v-html="content.copyright || '©'" /> {{ getCurrentYear }}
      </p>

      <nav class="footer-links">
        <ul>
          <li
            v-for="(link, index) in content.links"
            :key="index"
          >
            <a :href="link.url">{{ link.text }}</a>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script>

export default {
  data() {
    return {
      content: this.$quizData.footer,
      getCurrentYear: new Date().getFullYear(),
    }
  },
}
</script>
