// javascript/packs/application.js
import Vue from "vue";
import Vuex from "vuex";
import NorthForm from "north-form";
import Vuelidate from "vuelidate";
import VueTheMask from "vue-the-mask";
import store from "../store/index";
import App from "../App.vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'

const VueScrollTo = require("vue-scrollto");

// Dev
// Vue.use(VueReCaptcha, { siteKey: '6LeydnEeAAAAAFRkNi6jIgC0shIIhl_kakz4jgSs' })
// Prod
Vue.use(VueReCaptcha, { siteKey: '6Leae3EeAAAAAH_XR25BYarmxwQpdHhlUJdiqs2-' })
Vue.use(Vuex);
Vue.use(NorthForm, { store });
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(VueScrollTo);
Vue.config.productionTip = false;

document.addEventListener("DOMContentLoaded", () => {
  const element = document.getElementById("north-form");
  if (element) {
    const quizData = JSON.parse(element.dataset.quiz);
    Vue.prototype.$quizData = quizData;
    new Vue({
      el: "#north-form",
      store,
      render: (h) => h(App),
    });
  }
});
