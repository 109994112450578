<template>
  <section
    class="press-cards-section page-section"
    :class="content.classes"
  >
    <div class="container">
      <TitleBlock :content="content" />
      <div class="press-cards-content">
        <div
          v-for="(card, index) of content.cards"
          :id="card.id"
          :key="index"
          class="press-card"
          :class="`index-${index}`"
        >
          <MediaBlock :content="card" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TitleBlock from './shared/TitleBlock.vue'
import MediaBlock from './shared/MediaBlock.vue'

export default {
  components: {
    TitleBlock,
    MediaBlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
