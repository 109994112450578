<template>
  <div class="progress-bar">
    <div
      class="progress"
      :style="{ width: 100 / progress + '%' }"
    />
    <div class="progress-words">
      Question {{ progressAsWords }}
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      multiquestions: 0,
    }
  },
  computed: {
    current() {
      return this.currentStep - this.multiquestions
    },
    currentStep() {
      return this.$store.getters.ADVANCE_QUIZ_DATA.currentStep
    },
    previousQuestionType() {
      return this.$store.getters.QUIZDATA.sequences[this.currentStep - 1].questions[0].type
    },
    numberOfQuestions() {
      let numberOfQuestionsExcludeMultiSelect = 0
      this.$root.$store.getters.QUIZDATA.sequences.forEach(sequence => {
        // loop through sequence.questions and count if not multiselect
        sequence.questions.forEach(question => {
          if (question.type !== 'multiselect') {
            numberOfQuestionsExcludeMultiSelect++
          }
        })
      })
      return numberOfQuestionsExcludeMultiSelect
    },
    progress() {
      return this.numberOfQuestions / (this.current + 1)
    },
    progressAsWords() {
      return `${this.current + 1} of ${this.numberOfQuestions}`
    },
  },
  watch: {
    currentStep() {
      this.updateNumberOfMultiSelectQuestions()
    },
  },
  methods: {
    updateNumberOfMultiSelectQuestions() {
      if (this.previousQuestionType === 'multiselect') {
        this.multiquestions++
      }
    },
  },
}
</script>
